// App.js
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { nip19 } from 'nostr-tools';
import './App.css';

const API_URL = process.env.REACT_APP_API_URL || '/api';

// Constants
const NETWORKS = {
  NOSTR: 'nostr',
  BLUESKY: 'bluesky',
  MASTODON: 'mastodon'
};

const TAB_CONFIG = [
  { id: 'sources', icon: '📈', label: 'Sources' },
  { id: 'gov', icon: '🏛️', label: 'Gov' },
  { id: 'econ', icon: '🪙', label: 'Econ' },
  { id: 'sci', icon: '🧬', label: 'Sci' },
  { id: 'film', icon: '🍿', label: 'Film' },
  { id: 'pod', icon: '🎙️', label: 'Pod' },
  { id: 'music', icon: '🎸', label: 'Music' },
  { id: 'saved', icon: '📎', label: 'Saved' }
];

// Components
const LoadingIndicator = () => (
  <div className="loading-indicator">
    <div className="spinner"></div>
  </div>
);

const ErrorMessage = ({ network, message }) => (
  <div className="error-message">
    Error loading {network} feed: {message}
  </div>
);

const BookmarkButton = ({ onClick, isBookmarked }) => (
  <button
    onClick={onClick}
    className={`bookmark-button ${isBookmarked ? 'bookmarked' : ''}`}
    aria-label={isBookmarked ? 'Remove bookmark' : 'Add bookmark'}
  >
    {isBookmarked ? '★' : '☆'}
  </button>
);

const LinkPreview = ({ url, title, description, imageUrl, favicon }) => (
  <div className="link-preview">
    <a href={url} target="_blank" rel="noopener noreferrer" className="link-card">
      <div className="link-content">
        <div className="link-text">
          {favicon && (
            <img 
              src={favicon} 
              alt="" 
              className="favicon"
              onError={(e) => e.target.style.display = 'none'}
            />
          )}
          <h4 className="link-title">{title || url}</h4>
          {description && <p className="link-description">{description}</p>}
        </div>
        {imageUrl && (
          <div className="link-image">
            <img 
              src={imageUrl} 
              alt={title || "Link preview"} 
              onError={(e) => e.target.parentElement.style.display = 'none'}
            />
          </div>
        )}
      </div>
    </a>
  </div>
);

const BlueskyContent = ({ post, markdown }) => {
  const getEmbedData = () => {
    if (post.embed?.$type === 'app.bsky.embed.external') {
      const { uri, title, description, thumb } = post.embed.external;
      return {
        url: uri,
        title,
        description,
        imageUrl: thumb,
        favicon: `https://www.google.com/s2/favicons?domain=${new URL(uri).hostname}`
      };
    }
    return null;
  };

  const getLinkFromFacets = () => {
    if (!post.facets || !Array.isArray(post.facets)) return null;
    
    const linkFeature = post.facets.find(facet => 
      facet.features?.some(feature => feature.$type === 'app.bsky.richtext.facet#link')
    );
    
    if (linkFeature) {
      const uri = linkFeature.features.find(f => f.$type === 'app.bsky.richtext.facet#link').uri;
      return {
        url: uri,
        title: uri,
        favicon: `https://www.google.com/s2/favicons?domain=${new URL(uri).hostname}`
      };
    }
    return null;
  };

  const embedData = getEmbedData() || getLinkFromFacets();

  return (
    <div className="post-content">
      <div dangerouslySetInnerHTML={{ __html: markdown }} />
      {embedData && <LinkPreview {...embedData} />}
    </div>
  );
};

const PostFooter = ({ item, source, activeTab }) => {
  const getAuthorInfo = () => {
    switch (source) {
      case NETWORKS.BLUESKY:
        return {
          name: item.post.author.displayName,
          url: `https://bsky.app/profile/${item.post.author.did}/post/${item.post.uri.split('/').pop()}`
        };
      case NETWORKS.NOSTR:
        return {
          name: `${nip19.npubEncode(item.pubkey).slice(0, 12)}...`,
          url: `https://njump.me/${nip19.noteEncode(item.id)}`
        };
      case NETWORKS.MASTODON:
        return {
          name: item.account.displayName,
          url: item.url
        };
      default:
        return { name: '', url: '' };
    }
  };

  const getNetworkIcon = () => {
    switch (source) {
      case NETWORKS.BLUESKY: return '🦋';
      case NETWORKS.NOSTR: return '🕊️';
      case NETWORKS.MASTODON: return '🐘';
      default: return '';
    }
  };

  const author = getAuthorInfo();

  return (
    <div className="row">
      <a
        style={{ marginRight: 'auto', fontSize: '10pt' }}
        className="viewLink"
        href={author.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        by {author.name}
      </a>
      <a
        style={{ marginLeft: 'auto' }}
        className="viewLink"
        href={author.url}
        target="_blank"
        rel="noopener noreferrer"
      >
        from {getNetworkIcon()}{source} {TAB_CONFIG[activeTab].label}
      </a>
    </div>
  );
};

const NostrContent = ({ content }) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  const parts = content.split(urlRegex);

  return (
    <div className="post-content">
      {parts.map((part, index) => {
        if (part.match(urlRegex)) {
          try {
            const url = new URL(part);
            return (
              <LinkPreview
                key={index}
                url={part}
                title={url.hostname}
                favicon={`https://www.google.com/s2/favicons?domain=${url.hostname}`}
              />
            );
          } catch {
            return <span key={index}>{part}</span>;
          }
        }
        return <span key={index}>{part}</span>;
      })}
    </div>
  );
};

const MastodonContent = ({ content }) => {
  const urlRegex = /(https?:\/\/[^\s<]+[^<.,:;"')\]\s])/g;
  const links = content.match(urlRegex) || [];
  const textContent = content.replace(urlRegex, '');

  return (
    <div className="post-content">
      <div dangerouslySetInnerHTML={{ __html: textContent }} />
      {links.map((link, index) => {
        try {
          const url = new URL(link);
          return (
            <LinkPreview
              key={index}
              url={link}
              title={url.hostname}
              favicon={`https://www.google.com/s2/favicons?domain=${url.hostname}`}
            />
          );
        } catch {
          return null;
        }
      })}
    </div>
  );
};

const NetworkToggle = ({ network, icon, description, isEnabled, onToggle }) => (
  <div className="network-button-container">
    <button
      onClick={() => onToggle(network)}
      className={`network-button ${isEnabled ? 'active' : ''}`}
      aria-label={`Toggle ${network}`}
    >
      {icon} {network.charAt(0).toUpperCase() + network.slice(1)}
    </button>
    <div className="tooltip" role="tooltip">
      <p>{description}</p>
      <a
        href={`https://${network === 'nostr' ? 'nostr.org' : network === 'bluesky' ? 'bsky.social/about' : 'joinmastodon.org'}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn more
      </a>
    </div>
  </div>
);

const AppHeader = ({ networks, onNetworkToggle }) => (
  <div className="appBar">
    <div /> {/* Empty div for grid layout */}
    <h1 className="appTitle">Community Sources</h1>
    <div className="networks">
      {Object.entries(networks).map(([network, isEnabled]) => (
        <NetworkToggle
          key={network}
          network={network}
          icon={network === NETWORKS.NOSTR ? '🕊️' : network === NETWORKS.BLUESKY ? '🦋' : '🐘'}
          description={`${network.charAt(0).toUpperCase() + network.slice(1)} is a decentralized social network.`}
          isEnabled={isEnabled}
          onToggle={onNetworkToggle}
        />
      ))}
    </div>
  </div>
);

const PostModal = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal">
        <div className="modal-content">
          <h2>Post to Community Sources</h2>
          
          <p>
            By posting on Bluesky, NOSTR (use our relay wss://sources.nostr1.com), or Mastodon and including a cited link from one of the following
            sources, your post will automatically appear in our custom feeds.
          </p>
          <h2>🔹 Government Websites</h2>
          <ul>
              <li>U.S. Government: <strong>.gov</strong></li>
              <li>Argentina Government: <strong>.gob.ar</strong></li>
          </ul>
          <h2>🔹 Economy & Finance</h2>
          <ul>
              <li><a href="https://bea.gov">bea.gov</a></li>
              <li><a href="https://stlouisfed.org">stlouisfed.org</a></li>
              <li><a href="https://worldbank.org">worldbank.org</a></li>
              <li><a href="https://bls.gov">bls.gov</a></li>
              <li><a href="https://imf.org">imf.org</a></li>
              <li><a href="https://oecd.org">oecd.org</a></li>
          </ul>
          <h2>🔹 Science & Research</h2>
          <ul>
              <li><a href="https://arxiv.org">arxiv.org</a></li>
              <li><a href="https://nih.gov">nih.gov</a></li>
              <li><a href="https://nasa.gov">nasa.gov</a></li>
              <li><a href="https://science.org">science.org</a></li>
              <li><a href="https://cell.com">cell.com</a></li>
          </ul>
          
          <h2>🔹 Sports</h2>
          <ul>
              <li><a href="https://espn.com">espn.com</a></li>
              <li><a href="https://nba.com">nba.com</a></li>
              <li><a href="https://mlb.com">mlb.com</a></li>
              <li><a href="https://nhl.com">nhl.com</a></li>
              <li><a href="https://mls.com">mls.com</a></li>
          </ul>
          
          <h2>🔹 Technology</h2>
          <ul>
              <li><a href="https://techcrunch.com">techcrunch.com</a></li>
              <li><a href="https://wired.com">wired.com</a></li>
              <li><a href="https://arstechnica.com">arstechnica.com</a></li>
              <li><a href="https://cnet.com">cnet.com</a></li>
              <li><a href="https://theverge.com">theverge.com</a></li>
          </ul>
          
          <h2>🔹 Books & Literature</h2>
          <ul>
              <li><a href="https://books.google.com">books.google.com</a></li>
              <li><a href="https://amazon.com/books">amazon.com/books</a></li>
              <li><a href="https://goodreads.com">goodreads.com</a></li>
              <li><a href="https://barnesandnoble.com">barnesandnoble.com</a></li>
              <li><a href="https://bookshop.org">bookshop.org</a></li>
          </ul>
          
          <h2>🔹 Movies & TV</h2>
          <ul>
              <li><a href="https://imdb.com">imdb.com</a></li>
              <li><a href="https://rottentomatoes.com">rottentomatoes.com</a></li>
              <li><a href="https://netflix.com">netflix.com</a></li>
              <li><a href="https://hulu.com">hulu.com</a></li>
              <li><a href="https://disneyplus.com">disneyplus.com</a></li>
          </ul>
          <h2>🔹 News Media</h2>
          <ul>
              <li><a href="https://nytimes.com">nytimes.com</a></li>
              <li><a href="https://washingtonpost.com">washingtonpost.com</a></li>
              <li><a href="https://economist.com">economist.com</a></li>
              <li><a href="https://pbs.org">pbs.org</a></li>
              <li><a href="https://wsj.com">wsj.com</a></li>
              <li><a href="https://theguardian.com">theguardian.com</a></li>
              <li><a href="https://bbc.com">bbc.com</a></li>
              <li><a href="https://reuters.com">reuters.com</a></li>
              <li><a href="https://apnews.com">apnews.com</a></li>
              <li><a href="https://npr.org">npr.org</a></li>
              <li><a href="https://bloomberg.com">bloomberg.com</a></li>
              <li><a href="https://politico.com">politico.com</a></li>
              <li><a href="https://axios.com">axios.com</a></li>
              <li><a href="https://latimes.com">latimes.com</a></li>
              <li><a href="https://ft.com">ft.com</a></li>
          </ul>
          
          
          <button className="close-button" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

const AgeConfirmationModal = ({ onConfirm }) => (
  <div className="modal-overlay">
    <div className="modal">
      <div className="modal-content">
        <h2>Welcome to Community Sources</h2>
        <h4>An effort to bring the best out of social media and promote citizen journalism.</h4>
        <p>
          We encourage respectful behavior, but due to the unpredictable nature of content on social
          media, we ask that only people 16 years or older use the app.
        </p>
        <button className="confirm-button" onClick={onConfirm}>
          Continue (I am 16 or older)
        </button>
      </div>
    </div>
  </div>
);

// Custom Hooks
const useLocalStorage = (key, initialValue) => {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.error('Error reading from localStorage:', error);
      return initialValue;
    }
  });

  const setValue = value => {
    try {
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error('Error writing to localStorage:', error);
    }
  };

  return [storedValue, setValue];
};

// Utility function for formatting time
const formatTimeAgo = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();
  const differenceInSeconds = Math.floor((now - date) / 1000);
  
  const timeUnits = [
    { unit: 'day', seconds: 86400 },
    { unit: 'hour', seconds: 3600 },
    { unit: 'minute', seconds: 60 },
    { unit: 'second', seconds: 1 }
  ];

  for (const { unit, seconds } of timeUnits) {
    if (differenceInSeconds >= seconds) {
      const value = Math.floor(differenceInSeconds / seconds);
      return `${value} ${unit}${value !== 1 ? 's' : ''} ago`;
    }
  }
  
  return 'just now';
};

// Main App Component
const App = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [enabledNetworks, setEnabledNetworks] = useLocalStorage('enabledNetworks', {
    [NETWORKS.NOSTR]: true,
    [NETWORKS.BLUESKY]: true,
    [NETWORKS.MASTODON]: true
  });
  const [isAgeConfirmed, setIsAgeConfirmed] = useLocalStorage('ageConfirmed', false);
  const [bookmarks, setBookmarks] = useLocalStorage('bookmarks', []);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [feed, setFeed] = useState({ blueskyFeed: [], nostrFeed: [], mastodonFeed: [] });
  const [loading, setLoading] = useState(true);
  const [errors, setErrors] = useState({});

  const preferredLanguages = navigator.language || 'en-US';

  useEffect(() => {
    if (activeTab !== TAB_CONFIG.length - 1) {
      const fetchFeed = async () => {
        setLoading(true);
        setErrors({});
        
        try {
          const response = await fetch(
            `${API_URL}/feed?activeTab=${activeTab}&preferredLanguages=${preferredLanguages}`
          );
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setFeed(data);
        } catch (error) {
          console.error('Error fetching feeds:', error);
          setErrors({
            bluesky: 'Failed to fetch Bluesky feed',
            nostr: 'Failed to fetch Nostr feed',
            mastodon: 'Failed to fetch Mastodon feed'
          });
        } finally {
          setLoading(false);
        }
      };

      fetchFeed();
    } else {
      setLoading(false);
    }
  }, [activeTab, preferredLanguages]);

  const handleBookmark = useCallback((item) => {
    setBookmarks(prev => {
      const exists = prev.some(bookmark => bookmark.id === item.id);
      if (exists) {
        return prev.filter(bookmark => bookmark.id !== item.id);
      }
      return [...prev, item];
    });
  }, []);

const toggleNetwork = useCallback((network) => {
    setEnabledNetworks(prev => ({
      ...prev,
      [network]: !prev[network]
    }));
  }, []);

  const getNetworkToggleState = (network) => {
    const enabledCount = Object.values(enabledNetworks).filter(Boolean).length;
    return {
      isEnabled: enabledNetworks[network],
      isDisabled: enabledNetworks[network] && enabledCount === 1
    };
  };

  const filteredFeed = useMemo(() => {
    if (activeTab === TAB_CONFIG.length - 1) return bookmarks;

    return Object.entries(enabledNetworks)
      .filter(([, isEnabled]) => isEnabled)
      .flatMap(([network]) => {
        const networkFeed = feed[`${network}Feed`] || [];
        return networkFeed.map(item => ({ ...item, source: network }));
      })
      .sort((a, b) => {
        const dateA = new Date(a.source === NETWORKS.NOSTR ? a.created_at * 1000 : a.post?.indexedAt || a.createdAt);
        const dateB = new Date(b.source === NETWORKS.NOSTR ? b.created_at * 1000 : b.post?.indexedAt || b.createdAt);
        return dateB - dateA;
      });
  }, [activeTab, enabledNetworks, feed, bookmarks]);

  if (!isAgeConfirmed) {
    return <AgeConfirmationModal onConfirm={() => setIsAgeConfirmed(true)} />;
  }

  return (
    <div className="app-container">
      <AppHeader
        networks={enabledNetworks}
        onNetworkToggle={toggleNetwork}
      />
      
      <nav className="tabs" role="tablist">
        {TAB_CONFIG.map((tab, index) => (
          <button
            key={tab.id}
            role="tab"
            aria-selected={activeTab === index}
            onClick={() => setActiveTab(index)}
            className={activeTab === index ? 'active' : ''}
          >
            {tab.icon} {tab.label}
          </button>
        ))}
      </nav>

      <main className="feed">
        {loading && <LoadingIndicator />}
        {Object.entries(errors).map(([network, error]) => (
          error && <ErrorMessage key={network} network={network} message={error} />
        ))}
        
        {!loading && filteredFeed.length === 0 && (
          <p className="no-posts">
            {activeTab === TAB_CONFIG.length - 1 ? 'No saved posts' : 'No posts available'}
          </p>
        )}
        
        {filteredFeed.map(item => (
          <article key={item.id || item.post?.uri} className="item">
            <BookmarkButton
              onClick={() => handleBookmark(item)}
              isBookmarked={bookmarks.some(b => b.id === item.id)}
            />
            
            <time dateTime={
              item.source === NETWORKS.NOSTR 
                ? new Date(item.created_at * 1000).toISOString()
                : item.post?.indexedAt || item.createdAt
            }>
              {formatTimeAgo(
                item.source === NETWORKS.NOSTR
                  ? new Date(item.created_at * 1000).toISOString()
                  : item.post?.indexedAt || item.createdAt
              )}
            </time>

            {item.source === NETWORKS.BLUESKY && (
              <BlueskyContent post={item.post} markdown={item.markdown} />
            )}
            {item.source === NETWORKS.NOSTR && (
              <NostrContent content={item.content} />
            )}
            {item.source === NETWORKS.MASTODON && (
              <MastodonContent content={item.content} />
            )}

            <PostFooter 
              item={item}
              source={item.source}
              activeTab={activeTab}
            />
          </article>
        ))}
      </main>

      <PostModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
      
      <button 
        className="fab"
        onClick={() => setIsModalOpen(true)}
        aria-label="Create new post"
      >
        +
      </button>
    </div>
  );
};

export default App;